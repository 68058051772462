import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class Menu extends Component {
  static displayName = Menu.name;

  render() {
    return (
      <div style={{ marginBottom: '60px', marginTop: '40px', textAlign: 'center' }}>
        <Helmet>
          <title>Speisekarte - Forsthaus Klaushof</title>
          <meta name="keywords" content="Forsthaus, Klaushof, Forsthaus Klaushof, Bad Kissingen, Kirchhof, Jan Kirchhof, Restaurant, Speisekarte, Menü, Essen, Karte" />
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Speisekarte</h1>
        <p>Aktuell stellen wir unsere Speisekarte zusammen, daher müsst ihr euch noch etwas gedulden.</p>
      </div>
    );
  }
}
